<template>
    <div class="page-layout page-container">
        <Breadcrumbs />
        <router-view v-bind="$attrs" keep-alive/>
    </div>
</template>

<script>
import Breadcrumbs from "@common/components/base/Breadcrumbs";

export default {
    name: "PageLayout",
    components: {
        Breadcrumbs
    },
}
</script>

<style scoped>
::v-deep(.invalid-feedback){
    padding: 0.5rem;
    background: #F39E58;
    text-shadow: none;
}
::v-deep(.invalid-feedback>div){
    color: black;
}
::v-deep(.block h2){
    font-size: 1.4rem;
}
::v-deep(.block){
    margin: 1rem;
    /* margin-left: 1rem;
    margin-right: 1rem; */
}
::v-deep(.green_block a){
    color: #E2E8D4;
    color: var(--light-color);
    text-decoration: underline;
}
::v-deep(.base-form .row){
    text-transform: uppercase;
}
::v-deep(.base-form .row .col-label){
    /* width: 30%;
    margin-right: 1rem; */
    padding: 0.5rem 0;
}
::v-deep(.base-form .row .col-label>div){
    background-color: #344b1b;
    background-color: var(--dark-color);
    text-align: right;
    padding: 0.5rem;
    
    line-height: 1rem;
}

::v-deep(.base-form .row:hover),::v-deep(.base-form .row:focus-within){
    background: none;
}
::v-deep(.base-form .row .col-input){
    padding: 0.5rem 0;
}
::v-deep(.base-form .row .col-input input),::v-deep(.base-form .row .col-input textarea){
    width: 100%;
    padding: 0.5rem;
    background-color: #E2E8D4;
    background-color: var(--light-color);
    border: none;
}
::v-deep(.base-form .row .col-input textarea){
    height: 10rem;
}
::v-deep(.base-form .buttons-block){
    text-align: right;
}
::v-deep(.base-form .btn){
    color: #344b1b;
    color: var(--dark-color);

    background-color: #E2E8D4;
    background-color: var(--light-color);
    border-radius: 0;
    padding: 0.7rem 2rem;
    font-weight: 700;
    text-transform: uppercase;
}
::v-deep(.form-title){
    text-transform: uppercase;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-bottom: 5px dashed  var(--light-color);
}
::v-deep(.green_block .text-cell ol li::marker){
    color: #E2E8D4;
    font-size: 1.4rem;
}
::v-deep(.text-cell>ol>li::marker){
    font-size: 1.4rem;
}
::v-deep(.text-cell ol){
    padding: 1rem;
}
::v-deep(.text-cell ol>li){
    background-color: #E2E8D4;
    background-color: var(--light-color);
    padding: 0.5rem;
    margin: 0.5rem 0 0.5rem 3rem;
    color: #344b1b;
    color: var(--dark-color);
    text-shadow: none;
}
/* ::v-deep(.page-view>h1){
    margin: 1rem;
    border-bottom: 1px solid #b8bdae;
    padding: 0.5rem 2rem;
} */

@media screen and (max-width: 600px) {
  ::v-deep(.base-form .row .col-label>div){
        text-align: left;
    }

    
    ::v-deep(.text-cell ol>li){
        padding: 0;
        margin: 0;
        display: block;
    }
    ::v-deep(.text-cell ol){
        padding: 0;
        margin: 0;
    }
    
}
</style>