<template>
    <ul class="breadcrumbs">
        <li v-for="(breadcrumb,index) in preparedCrumbs" :key="index" class="breadcrumb">
            <RouterLinkLocal v-if="breadcrumb.link !== false" :translate="false" :link="breadcrumb.link"> 
                {{ breadcrumb.name }} 
            </RouterLinkLocal>
           <span v-else> 
               {{ breadcrumb.name }} 
           </span>
        </li>
    </ul>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
    name: "Breadcrumbs",
    setup(){
        const router = useRouter();
        const breadcrumbs = computed(() => router.currentRoute.value.meta.breadcrumbs);

        const link_params = computed(() => router.currentRoute.value.params);

        const i18n = useI18n();
        
        const store = useStore();

        const preparedCrumbs = computed(() => {
            if(!Array.isArray(breadcrumbs.value)){
                return [];
            }
            let array = [];
            breadcrumbs.value.forEach(breadcrumb => {
                let crumb = {
                    name: '...',
                    link: false
                };
                if(Object.prototype.hasOwnProperty.call(breadcrumb,'key')){
                    crumb.name = store.getters['breadcrumbs/'+breadcrumb.key];
                } else if(Object.prototype.hasOwnProperty.call(breadcrumb,'name')){
                    if(breadcrumb.name.startsWith('!')){
                        crumb.name = breadcrumb.name.substring(1);
                    } else {
                        crumb.name = i18n.t(breadcrumb.name);
                    }
                } else if(Object.prototype.hasOwnProperty.call(breadcrumb,'store')){
                    crumb.name = store.getters[breadcrumb.store];
                }


                if(Object.prototype.hasOwnProperty.call(breadcrumb,'link')){
                    let link = '';
                    breadcrumb.link.split('/').forEach(element => {
                        link += '/';
                        if(element.startsWith('!')){
                            link += element.substring(1);
                        } else if(element.startsWith(':')){
                            let param = element.substring(1);
                            if(Object.prototype.hasOwnProperty.call(link_params.value,param)){
                               link += link_params.value[param]; 
                            }                            
                        } else {
                            link += i18n.t(element);
                        }
                    });
                    crumb.link = link;
                }
                array.push(crumb);
            });
            return array;
        })
        
        return {
            preparedCrumbs,
        }
    }
}
</script>

<style>
.breadcrumbs{
    list-style:none; 
    margin:0.5rem; 
    padding:0; 
    display:inline-block;
}
.breadcrumb{
    display:inline-block;
    color: #344b1b;
    color: var(--dark-color);
    line-height: 0.8rem;
    font-size: 0.8rem;
}
.breadcrumb:after{ 
    padding: 0 0.5rem; 
    color: #888; 
    content: "/"; 
}
.breadcrumb:last-child::after{ 
    padding: 0; 
    color: #888; 
    content: ""; 
}

</style>
